<template>
    <AppLayout>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
            <div class="widget widget-six">

                <projectMenu :project="project"></projectMenu>

                <div class="widget-content">
                    <div v-if="$page.props.errors">
                        <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
                            <li class="alert alert-danger">{{ error }}</li>
                        </ul>
                    </div>

                    <div class="text-end mb-3">
                        <ProjectsHistory
                            :project="project"
                            :model="'TaskHistory'">
                        </ProjectsHistory>
                    </div>

                    <div class="card-body">
                        <form v-if="isAdmin()" method="post" @submit.prevent="changePermissions">
                            <h4>Osoby, które mogą edytować zadania</h4>
                            <select id="select_canDoChangesEmail" class="select2 w-50" name="emails[]" multiple>
                                <option v-for="user in users">{{ user.email }}</option>
                            </select>
                            <button id="saveEmails" class="btn btn-sm btn-primary mt-2" type="submit" style="display: none">Zapisz</button>
                        </form>

                        <div class="row mt-3">
                            <h4>Zadania</h4>

                            <ProjectsTasksGrid
                                :projectId="projectId" :usersEmails="preparedUsersEmails()" :usersCanChange="projectTasksUsersCanChanges">
                            </ProjectsTasksGrid>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>

</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";
import ProjectsHistory from "@/Pages/Projects/Histories/ProjectsHistory.vue";
import ProjectsTasksGrid from "@/Pages/Projects/Components/ProjectsTasksGrid.vue";
import {inArray} from "@syncfusion/ej2-grids";

export default {
    components: {ProjectsTasksGrid, ProjectsHistory, AppLayout, projectMenu},
    props: {
        project: Object,
        users: Object,
        projectTasksUsersCanChanges: Object
    },

    data() {
        return {
            projectId: this.project.id,
            csrfToken: null,
        }
    },

    mounted() {
        this.csrfToken = document.querySelector('meta[name="csrf-token"]').content;

        let sel2 = $('#select_canDoChangesEmail').select2({
            multiple: true,
            placeholder: 'Wybierz maile',
            theme: 'bootstrap-5'
        }).on('select2:select select2:unselect', (e) => {
            $('#saveEmails').show();
        })

        sel2.val(this.projectTasksUsersCanChanges).trigger('change');
    },

    methods: {
        inArray,
        route,
        preparedUsersEmails(){
          return this.users.map(item => (
                {'person': item.email}
            ));
        },

        isRouteActive(routeName) {
            return route().current() === routeName;
        },

        isAdmin() {
            return this.$page.props.user.roles.find(role => role === 'ADMIN');
        },

        changePermissions() {
            let newEmails = $('#select_canDoChangesEmail').val();
            axios.post(route('project.tasks.users-can-changes', {
                project: this.project.id,
                emails: newEmails
            })).then(response => {
                let error = !!response.data.error;
                if (error) {
                    console.log(response);
                } else {
                    $('#saveEmails').hide();
                }

                Toast.fire({
                    position: 'top-end',
                    toast: true,
                    icon: response.data.icon,
                    title: response.data.title,
                    html: response.data.message,
                    showClass: {popup: 'animate__animated animate__fadeInDown'},
                    hideClass: {popup: 'animate__animated animate__fadeOutUp'},
                    timer: 2500,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: true
                });
            })
        }
    },
}
</script>
