<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <h4 class="me-3 my-0">Domyślni Dostawcy w Projektach</h4>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <table class="table">
            <thead>
            <tr>
              <th>Kategoria</th>
              <th>Dostawca 1</th>
              <th>Dostawca 2</th>
              <th>Dostawca 3</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(category, index) in categories" :key="index">
              <td>{{ category.name }}</td>
              <td>
                <template v-if="defaultSuppliers" v-for="supplier in defaultSuppliers">
                  <template v-for="supCategory in supplier.customer_categories">
                    <template v-if="supCategory.id === category.id">
                      <div v-if="supplier.supplier_no === 1 && supplier.category_id === category.id">
                        <div class="d-flex align-items-center mb-2">
                          <span style="min-width: 40%">{{ supplier.short_name }}</span>
                          <form class="ms-1" @submit.prevent="deleteDeliverer(supplier)">
                            <button type="submit" class="btn btn-xs btn-danger"><i class="bi bi-x-circle"></i></button>
                          </form>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
                <AddSupplierModal :customers="customers"
                                  :category="category"
                                  :delivererNo="1"
                                  :elemId="index + 1">
                </AddSupplierModal>
              </td>
              <td>
                <template v-if="defaultSuppliers" v-for="supplier in defaultSuppliers">
                  <template v-for="supCategory in supplier.customer_categories">
                    <template v-if="supCategory.id === category.id">
                      <div v-if="supplier.supplier_no === 2 && supplier.category_id === category.id">
                        <div class="d-flex align-items-center mb-2">
                          <span style="min-width: 40%">{{ supplier.short_name }}</span>
                          <form class="ms-1" @submit.prevent="deleteDeliverer(supplier)">
                            <button type="submit" class="btn btn-xs btn-danger"><i class="bi bi-x-circle"></i></button>
                          </form>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
                <AddSupplierModal :customers="customers"
                                  :category="category"
                                  :delivererNo="2"
                                  :elemId="index + 2000">
                </AddSupplierModal>
              </td>
              <td>
                <template v-if="defaultSuppliers" v-for="supplier in defaultSuppliers">
                  <template v-for="supCategory in supplier.customer_categories">
                    <template v-if="supCategory.id === category.id">
                      <div v-if="supplier.supplier_no === 3 && supplier.category_id === category.id">
                        <div class="d-flex align-items-center mb-2">
                          <span style="min-width: 40%">{{ supplier.short_name }}</span>
                          <form class="ms-1" @submit.prevent="deleteDeliverer(supplier)">
                            <button type="submit" class="btn btn-xs btn-danger"><i class="bi bi-x-circle"></i></button>
                          </form>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
                <AddSupplierModal :customers="customers"
                                  :category="category"
                                  :delivererNo="3"
                                  :elemId="index + 3000">
                </AddSupplierModal>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import AddSupplierModal from '@/Pages/Projects/DefaultSuppliers/AddSupplierModal.vue';

export default {
  components: {AppLayout, AddSupplierModal},
  props: {
    customers: Object,
    categories: Object,
    suppliers: Object
  },

  data() {
    return {
      defaultSuppliers: [],
      count: 0
    }
  },

  mounted() {
    this.processSuppliers(); // Wykonaj przetwarzanie danych na początku
  },

  watch: {
    // Obserwuj zmiany w `suppliers`
    suppliers: {
      handler: function (newSuppliers) {
        this.processSuppliers(); // Ponownie przetwórz dostawców, gdy dane się zmienią
      },
      deep: true, // Głębokie obserwowanie (sprawdza zmiany wewnątrz obiektów)
    }
  },

  methods: {
    /**
     * pobieranie suppliers i dodanie ich do defaultSuppliers
     */
    processSuppliers() {
      let defaultSuppliers = [];

      this.suppliers.forEach((supplier) => {
        let customer = this.customers.find((item) => item.id === supplier.customer_id);

        if (customer) {
          defaultSuppliers.push({
            id: supplier.id,
            short_name: customer.short_name,
            category_id: supplier.category_id,
            customer_categories: customer.customer_categories,
            supplier_no: supplier.deliverer_no
          });
        }
      });

      // Sortowanie tablicy `defaultSuppliers` według `short_name`
      defaultSuppliers.sort((a, b) => {
        if (a.short_name.toLowerCase() < b.short_name.toLowerCase()) return -1;
        if (a.short_name.toLowerCase() > b.short_name.toLowerCase()) return 1;
        return 0;
      });

      this.defaultSuppliers = defaultSuppliers;
    },

    async deleteDeliverer(supplier) {

      const response = await axios.delete(route('projects.default-suppliers.destroy', supplier));

      if (response.status === 200) {
        // usuń suppliera z obiektu
        const index = this.suppliers.findIndex((item) => item.id === supplier.id);
        if (index !== -1)
          this.suppliers.splice(index, 1);

      } else {
        console.log(response.data);
        await Toast.fire({
          position: 'top-end',
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });
      }
    }
  }
}

</script>
