<template>
  <div ref="indexProjects" id="projectsIndexShowProjects">
    <div class="widget-heading px-0">
      <div class="d-inline-flex align-items-center">
        <h4 class="ms-0 me-3 my-0" v-if="glowice">Lista głowic</h4>
        <h4 class="ms-0 me-3 my-0" v-else>Lista projektów</h4>
        <Link :href="route('projects.create')" class="btn btn-outline-primary"
              v-if="hasPermission('canEditBasicProject')">
          <i class="bi bi-plus-circle"></i>&nbsp;Utwórz Projekt
        </Link>

      </div>

      <div class="text-end mb-3">
        <div class="form-check form-switch form-check-inline form-switch-success">
          <input class="form-check-input" type="checkbox" role="switch" id="showArchivedProjects"
                 style="height:1.5em; width:3em;"
                 v-model="archivedProjectsShown"
                 @change="showArchivedProjects()">
          <label class="form-check-label" for="showArchivedProjects" style="margin: 3px 0 0 10px;">Pokaż
            zarchiwizowane</label>
        </div>

        <ProjectsHistory
            :project="{}"
            :model="'ProjectHistory'">
        </ProjectsHistory>
      </div>

    </div>

    <div class="custom-table">
      <div class="table-responsive">
        <table id="projectsTable" ref="dataTables" class="table table-hover">
          <thead>
          <tr>
            <th>Priorytet</th>
            <th>Id</th>
            <th>Status</th>
            <th>Komentarze</th>
            <th>Nazwa Projektu</th>
            <th>Klient</th>
            <th>Numer</th>
            <th>Otwarcie</th>
            <th>FAT</th>
            <th>SAT</th>
            <th>Kierownik Projektu</th>
            <th>Inżynier Projektu</th>
            <th>Rozpoczęcie prac</th>
            <th>Programista/Automatyk</th>
            <th>Lider Montażu</th>
            <th>Data odbioru</th>
          </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>

  <CommentModal></CommentModal>
</template>


<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import ProjectsHistory from "@/Pages/Projects/Histories/ProjectsHistory.vue";
import CommentModal from "@/Pages/Modals/Comments.vue";
import Modal from "bootstrap/js/dist/modal.js";

import {usePermission} from "@/composables/resources/js/composables/permissions.js";

const {hasPermission} = usePermission();

export default {
  components: {CommentModal, ProjectsHistory, hasPermission},

  props: {
    projects: Object,
    glowice: Boolean
  },

  data() {
    return {
      loading: false,
      allProjects: this.projects,
      table: null,
      archivedProjectsShown: false,
      hasPermission
    }
  },

  mounted() {
    this.initDataTable();
    this.initFilters();
    this.$refs.indexProjects.addEventListener('focusout', this.handleInputFocusOut);

    let self = this;
    this.$nextTick(() => {
      $(this.$refs.dataTables).on('click', '.modal-comment', async function () {
        let projectId = $(this).data('comment');
        $('.modal-content').attr('data-model', projectId);
        $('#commentModal').attr('data-model-id', projectId);
        await self.fetchComments($(this).data('comment'))

        var modalComm = new Modal(document.getElementById('commentModal'));
        modalComm.show();
        $('#modelId').html('');
      });

      $('#comment-form').submit(async function (event) {
        await self.addComment(event)
      });
    })
  },
  beforeDestroy() {
    this.$refs.indexProjects.removeEventListener('focusout', this.handleInputFocusOut);
    this.table.destroy();
  },

  methods: {
    route,

    showArchivedProjects() {
      this.table.draw();
    },

    initDataTable() {
      const self = this;
      const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;
      let url = route('get-projects-datatables');
      let stateSaveUrl = '/save-column-data?table=projects-list';
      let stateLoadUrl = '/get-table-data?table=projects-list';
      if (this.glowice) {
        url = route('get-glowice-datatables');
        stateSaveUrl = '/save-column-data?table=glowice-list';
        stateLoadUrl = '/get-table-data?table=glowice-list';
      }

      this.table = $(this.$refs.dataTables).DataTable({
        colReorder: true,
        rowReorder: checkPermissionsToRowReorder(),
        stateSave: true,
        serverSide: true,
        processing: true,
        ajax: {
          url: url, // endpoint do pobierania danych z serwera
          type: 'GET',
          data: function (params) {
            params.additional_param = 'value'; // Dodaj dodatkowe parametry, jeśli są potrzebne
            params.showArchived = self.archivedProjectsShown
            return params;
          },
          dataSrc: 'data',
        },
        stateSaveCallback: function (settings, data) {
          $.ajax({
            url: '/save-column-data?table=projects-list',
            data: data,
            type: 'POST',
            dataType: 'json',
            headers: {
              'X-CSRF-TOKEN': csrfToken, // Include the CSRF token in the headers
            },
            "success": function () {
              const datTab = $('.dataTable');
              // generuj link do wersji oferty
              let link = datTab.find('a[data-edit]');
              link.on('click', function (event) {
                event.preventDefault();
                const id = $(this).data('edit');
                self.generateEditProjectLink(id);
              });

            }
          });
        },
        stateLoadCallback: function (settings) {
          let o;
          $.ajax({
            url: '/get-table-data?table=projects-list',
            async: false,
            dataType: 'json',
            success: function (json) {
              o = json;
            }
          });
          let elem = this;
          self.initColumnSearch(elem, o)
          return o;
        },
        columns: [
          {
            width: "3%",
            name: 'priority',
            class: 'priority text-center',
            data: function (p) {
              let priority = p.priority ?? 0;

              return '<div style="cursor: move">' + priority + '<i class="ms-1 bi bi-list" style="font-size: 16px;"></i></div>';
            }
          },
          {
            width: "3%",
            name: 'id',
            class: 'project_id notEditable',
            isUnique: true,
            data: function (d) {
              return '<a class="text-primary" href="#" data-edit="' + d.id + '">' + d.id + '</a>';
            },
          },
          {
            name: 'status',
            class: 'status options',
            data: function (d) {
              let optionObject = ['PROJEKT', 'PROJEKT/PRODUKCJA/MONTAŻ/URUCHOMIENIE', 'PRODUKCJA/MONTAŻ/URUCHOMIENIE', 'MONTAŻ/URUCHOMIENIE', 'URUCHOMIENIE', 'GŁOWICE'];
              let options = `<option value='${d.status ?? ""}'>${d.status ?? ""}</option>`;
              for (let i = 0; i < optionObject.length; i++) {
                if (optionObject[i] !== d.status)
                  options += '<option value="' + optionObject[i] + '">' + optionObject[i] + '</option>';
              }

              let message = '<div class="statusMessage text-danger d-none small"></div>';

              let disabled = hasPermission('canEditBasicProject') ? '' : 'disabled ';

              return "<select style='width: 320px;font-size: 10px' name='status' " + disabled +
                  "data-projectId='" + d.id + "'" +
                  "class='form-select form-select-sm'>" + options + "</select>" + message;
            },
          },
          {
            name: 'last_comment',
            data: 'last_comment',
            render: function (data, idx, row) {
              let createdBy = '';
              return "<span class='text-nowrap'>" + data + "</span>" + '<button data-comment="' + row.id + '" class="modal-comment btn btn-xs btn-light-default"><i class="bi bi-chat-dots" style="font-size:.9rem"></i></button>';

            },
          },
          {
            name: 'name',
            class: 'name notEditable',
            data: function (d) {
              return '<a class="text-primary" href="#" data-edit="' + d.id + '">' + d.name + '</a>';
            },
          },
          {
            name: 'customer.name',
            class: 'customer  notEditable',
            data: function (row) {
              return row.customer.name ?? '';
            },
          },
          {
            name: 'number',
            class: 'number notEditable',
            data: function (d) {
              return `<a href="#" data-edit="${d.id}">
                        <div class="color-square" style='background-color: ${d.bg_color}; color: ${d.color}'>${d.number ?? ''}</div>
                      </a>`;
            },
          },
          {
            name: 'order_opening_date',
            class: 'order_opening_date notEditable',
            data: function (o) {
              return o.order_opening_date ?? '';
            }
          },
          {
            name: 'fat',
            class: 'fat',
            data: function (d) {
              let message = '<div class="fatMessage text-danger d-none small"></div>';
              let disabled = hasPermission('canEditBasicProject') ? '' : ' disabled ';

              return `<input type="date" ${disabled}
                                    name="fat"
                                    data-projectId='${d.id}'
                                    value="${d.fat ?? ''}"
                                    class="form-control form-control-sm">` + message;
            },
          },
          {
            name: 'planned_completion_date',
            class: 'planned_completion_date',
            data: function (d) {
              let message = '<div class="satMessage text-danger d-none small"></div>';
              let disabled = hasPermission('canEditBasicProject') ? '' : ' disabled ';

              return `<input type="date" ${disabled}
                                    name="planned_completion_date"
                                    data-projectId='${d.id}'
                                    value="${d.planned_completion_date ?? ''}"
                                    class="form-control form-control-sm">` + message;
            },
          },
          {
            name: 'project_manager_id',
            class: 'project_manager_id notEditable',
            data: function (o) {
              if (!o.project_manager_id) {
                return '';
              }

              return `${o.project_manager.first_name ?? ''} ${o.project_manager.last_name ?? ''}`;

            },
          },
          {
            name: 'engineer_id',
            class: 'engineer_id notEditable',
            data: function (o) {
              if (!o.engineer_id) {
                return '';
              }

              return `${o.engineer.first_name ?? ''} ${o.engineer.last_name ?? ''}`;
            },
          },
          {
            name: 'start_working_date',
            class: 'start_working_date',
            data: function (d) {
              let message = '<div class="start_working_dateMessage text-danger d-none small"></div>';
              let disabled = hasPermission('canEditBasicProject') ? '' : ' disabled ';

              return `<input type="date" ${disabled}
                                    name="start_working_date"
                                    data-projectId='${d.id}'
                                    value="${d.start_working_date ?? ''}"
                                    class="form-control form-control-sm">` + message;
            },
          },
          {
            name: 'programmer_id',
            class: 'automation_manager_id notEditable',
            data: function (o) {
              if (!o.programmer_id) {
                return '';
              }

              return `${o.programmer.first_name ?? ''} ${o.programmer.last_name ?? ''}`;
            },
          },
          {
            name: 'assembly_leader_id',
            class: 'assembly_leader_id notEditable',
            data: function (o) {
              if (!o.assembly_leader_id) {
                return '';
              }

              return `${o.assembly_leader.first_name ?? ''} ${o.assembly_leader.last_name ?? ''}`;
            },
          },
          {
            name: 'deadline_for_completion',
            class: 'deadline_for_completion notEditable',
            data: function (d) {
              return d.deadline_for_completion;
            },
          },

        ],
        createdRow: function (row, data, dataIndex) {
          // po kliknieciu w 'td' przenies do edycji customera
          const link = $(row).find('a[data-edit]');
          link.on('click', function (event) {
            event.preventDefault();
            const id = $(this).data('edit');
            self.generateEditProjectLink(id);
          });

          // nadaj na TR styl przekreślenia i kolor backgroundu
          if (data.archived === 1) {
            $(row).addClass('strikeout');
          }
        },
      });

      function checkPermissionsToRowReorder() {
        if (!hasPermission('canEditBasicProject')) {
          return false;
        }
        return {
          dataSrc: 'priority',
          update: false,
          selector: 'td.priority',
          select: true,
          snapX: false,
          // zapisywanie priority niżej w kodzie (on row-reorder)
        };

      }

      this.table.on('row-reorder', function (e, diff, edit) {
        const reorderedData = diff.map(function (row) {
          return {
            // Pobierz identyfikator projektu
            id: parseInt($(row.node).find('.project_id [data-edit]').attr('data-edit')),
            // Nowa pozycja kolumny priority
            newPriority: $(row.node).index() + 1
          };
        });
        if (reorderedData.length === 0) {
          return false;
        }

        axios.post(route('projects.reorder-priority'), {'reordered': reorderedData})
            .then(response => {
              Toast.fire({
                position: 'top-end',
                toast: true,
                icon: response.data.icon,
                title: response.data.title,
                html: response.data.message,
                showClass: {popup: 'animate__animated animate__fadeInDown'},
                hideClass: {popup: 'animate__animated animate__fadeOutUp'},
                timer: 2500,
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: true
              });
            }).catch(error => {
          console.log(error);
        })
      });
    },

    initColumnSearch(elem, o) {
      setTimeout(() => {
        let api = elem.api();
        api.columns().eq(0).each((colIdx) => {
          let cell = document.querySelectorAll('#projectsTable .filters td')[api.column(colIdx).header().cellIndex];
          let titleCell = document.querySelectorAll('#projectsTable th')[api.column(colIdx).header().cellIndex];
          let searchText = o.columns[colIdx].search.search;
          let cleanSearchText = (typeof searchText === 'string') ? searchText.replace(/[()]/g, '') : '';

          if (cleanSearchText) {
            cell.innerHTML = `<input type="text" value="${cleanSearchText}" />`;
          } else {
            let title = titleCell.textContent;
            cell.innerHTML = `<input type="text" placeholder="${title}" />`;
          }

          // Dodaj klasy z nagłówka kolumny do komórki
          cell.classList.add(...titleCell.className.split(' ').filter(cls => cls && cls !== 'sorting'));

          let input = cell.querySelector('input');
          input.removeEventListener('keyup', handleInputChange);
          input.addEventListener('keyup', handleInputChange);
        });

        function handleInputChange(e) {
          e.target.setAttribute('title', e.target.value);
          api.column(e.target.parentNode.cellIndex).search(e.target.value).draw();
        }
      }, 500);
    },

    initFilters() {
      const headerRow = document.querySelector('#projectsTable thead tr');
      const inputRow = document.createElement('tr');

      Array.from(headerRow.querySelectorAll('th')).forEach(() => {
        const inputCell = document.createElement('td');
        const input = document.createElement('input');
        input.type = 'text';
        input.placeholder = 'Filter...';

        inputCell.classList.add('filters');
        inputCell.appendChild(input);
        inputRow.classList.add('filters');
        inputRow.appendChild(inputCell);
      });

      headerRow.after(inputRow);
    },

    handleInputFocusOut(event) {
      /**
       * edycja komórek
       * INPUT, SELECT, TEXTAREA
       * tylko, jeśli jest podany atrybut name- żeby ograniczyć do tabeli
       */
      let target = event.target;
      if ((target.tagName === 'INPUT' || target.tagName === 'SELECT' || target.tagName === 'TEXTAREA') && target.name !== '' && target.name.length > 0) {
        this.saveInputInCell(target.parentNode, target);
      }
    },

    generateEditProjectLink(id) {
      this.$inertia.visit(route('projects.show', {project: id}));
    },

    async saveInputInCell(td, input) {
      // jeżeli nie jest edytowalny to przerwij edycję
      if (td.classList.contains('notEditable')) {
        return;
      }

      // zapisywanie wartości, jeśli zmieniła wartość
      this.addLoader(td);
      await this.saveCellHandler(td, input);
      this.removeLoader(td);
    },

    async saveCellHandler(td, input) {
      let projectId = input.dataset.projectid;
      let column = input.name;
      let newValue = input.value;
      let toValidate = td.classList.contains('toValidate');

      await this.updateCell(td, projectId, column, newValue, toValidate)
    },

    async updateCell(td, projectId, column, newValue, toValidate) {
      await axios.post(route('projects.showProjects.update-cell'), {
        projectId: projectId,
        column: column,
        newValue: newValue
      }).then((response) => {
        // jeżeli nie trzeba walidować to nic więcej nie rób
        if (!toValidate) {
          return;
        }

        // pokaż wiadomość z walidacji
        if (response.data?.column === 'priority') {
          let message = td.querySelector('div.priorityMessage');
          message.classList.remove('d-none');
          message.innerHTML = response.data.message;
        } else {
          td.querySelector('div.priorityMessage').classList.add('d-none');
        }

      }).catch(error => {
        console.log(error);
      })
    },

    addLoader(toElem) {
      let div = document.createElement('div');
      div.className = 'spinner spinner-border text-primary me-2 align-self-center loader-sm spinner-abs';
      toElem.appendChild(div);
    },
    removeLoader(fromElem) {
      let loader = fromElem.querySelector('div.spinner');
      if (loader && loader.parentNode === fromElem)
        fromElem.removeChild(loader);
    },

    async addComment(event) {
      $('#submit-button').prop('disabled', true);
      $('#button-text').hide();
      $('#loading-spinner').show();
      event.preventDefault();
      this.loading = true;
      let projectId = $('.modal-content').data('model')
      const commentText = $('#comment-text').val();

      try {
        await axios.post(route('projects.add-comment', {project: projectId}), {
          comment: commentText
        }).then(() => {
          this.fetchComments(projectId)
          $('#comment-text').val('');
        });
      } catch (error) {
        console.error(error);
      } finally {
        // Re-enable the submit button and hide the loading spinner
        $('#submit-button').prop('disabled', false);
        $('#button-text').show();
        $('#loading-spinner').hide();
      }
    },

    async fetchComments(projectId) {
      this.loading = true;

      axios.get(route('projects.get-comments', {project: projectId})
      ).then(response => {
        this.comments = response.data;
        this.loading = false;
        $('#modelId').html(this.comments)
        this.customersLoaded = true;
        $('#modelId').html('');


        // Iterate through the comments and build the HTML
        this.comments.forEach(comment => {
          const commentHtml = `
                <div class="comment" style="margin-top: 15px">
                    <p class="comment-text"><strong>${comment.comment}</strong></p>
                    <div class="comment-info">
                        <p>Utworzony przez: ${comment.created_by_name}</p>
                        <p>Utworzony w dniu: ${comment.created_at}</p>
                    </div>
                    <hr/>
                </div>
            `;
          $('#modelId').append(commentHtml);
        });
        this.loading = false;
      }).catch(error => {
        console.log(error(error));
      })
    },
  }
}
</script>

<style>
#projectsIndexShowProjects .spinner-abs {
  position: absolute;
  top: 10px;
  right: 5px;
}

#projectsIndexShowProjects td {
  position: relative;
  padding: 2px 5px;
}

#projectsIndexShowProjects td.focus {
  border: 1px solid #3a94b0;
  border-radius: 6px;
}

.color-square {
  padding: 4px;
  text-align: center;
}
</style>
