<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addQuoteRequestModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dodaj do zapytania ofertowego</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="form" class="container-fluid">
              <div class="text-center">
                <label for="dueDate">Ostateczny termin dostarczenia odpowiedzi na zapytanie ofertowe</label>
              </div>
              <div class="mb-4 text-center mx-auto" style="max-width: 200px;">
                <ejs-datepicker
                    id="projectFileProgressId"
                    placeholder="Wybierz zakres dat"
                    :value="form.dueDate"
                    :format="dueDateFormat"
                    :weekNumber="true"
                ></ejs-datepicker>
              </div>

              <template v-for="(article, idx) in form.articles" :key="article.id">
                <table class="table">
                  <tbody>
                  <tr>
                    <td colspan="2">#{{ ++idx }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">
                      Nr zapytania ofertowego
                      <i class="bi bi-info-circle" data-bs-toggle="tooltip"
                         title="Można dopisać artykuł do jednego z poprzednich zapytań ofertowych. Jeśli jednak chcesz utworzyć nowe - pozostaw tę pozycję pustą."></i>
                    </td>
                    <td>
                      <Vueform>
                        <SelectElement id="joinToQuoteRequestNo" :object="true" :native="false" :search="true"
                                       @keyup="fetchQuoteRequestsNumbers" :filter-results="false"
                                       @change="event => setQuoteRequestNumber(article, event)"
                                       name="customer_id" :value="article.quote_request_number"
                                       autocomplete="off" :items="quoteRequestNumbers">
                        </SelectElement>
                      </Vueform>
                    </td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Nr katalogowy</td>
                    <td>{{ article.catalog_number }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Model</td>
                    <td>{{ article.model }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Potrzebna ilość</td>
                    <td><input class="form-control form-control-sm" type="number" step="0.1" min="0"
                               v-model="article.quantity_required"></td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Producent</td>
                    <td>
                      <template v-if="article.producer_short_name">
                        <span class="font-weight-bolder">{{ article.producer_short_name }}</span>
                      </template>
                      <template v-else>
                        <i>Brak domyślnego producenta</i>
                      </template>

                      <div v-if="isEmailRequired(article)" class="text-danger">Adres mailowy jest nieprawidłowy</div>
                      <input
                          class="form-control form-control-sm"
                          type="email"
                          placeholder="Wpisz email producenta"
                          v-model="article.producer_email"
                          required
                      />
                    </td>
                  </tr>

                  </tbody>
                </table>

              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="saveQuoteRequest" v-if="missingEmail">
              <button type="submit" class="btn btn-primary" :disabled="form.processing">
                <i class="bi bi-save me-2"></i>Wyślij zapytania ofertowe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {useForm} from "@inertiajs/vue3";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

export default {
  components: {'ejs-datepicker': DatePickerComponent},
  props: {
    project: Number | String,
    team: String,
    selectedItems: {
      type: Object,
      default: () => {
      }
    },
  },

  data() {
    return {
      quoteRequestNumbers: {},
      modalObj: null,
      dueDateFormat: 'dd.MM.yyyy',
      form: useForm({dueDate: this.defaultDueDate()})
    }
  },

  watch: {
    selectedItems: {
      immediate: true,
      handler(newItems) {
        if (!newItems) {
          return false;
        }

        this.form = useForm({
          dueDate: this.defaultDueDate(),
          project: parseInt(this.project),
          articles: newItems.map((article) => ({
            project: article.project_id,
            team: article.team_name,
            id: article.id,
            quote_request_number: null,
            catalog_number: article.article.catalog_number,
            model: article.article.model,
            quantity_required: article.quantity_required,
            producer_short_name: article.article.producer?.short_name || null,
            producer_email: article.article.producer?.customers_data.email || '',
            producer_id: article.article.producer_id || '',
          })),
        });
      },
    },
  },


  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
    this.fetchQuoteRequestsNumbers();
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },

  computed: {
    isEmailRequired() {
      return (article) => !article.producer_email || !this.emailPattern().test(article.producer_email);
    },

    missingEmail() {
      return this.form.articles?.every(article => this.emailPattern().test(article.producer_email));
    }
  },

  methods: {
    emailPattern() {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    },
    defaultDueDate() {
      const today = new Date();
      const nextWeek = new Date(today);
      nextWeek.setDate(today.getDate() + 7);
      const year = nextWeek.getFullYear();
      const month = String(nextWeek.getMonth() + 1).padStart(2, '0'); // Miesiąc jest 0-indeksowany
      const day = String(nextWeek.getDate()).padStart(2, '0');

      return `${day}.${month}.${year}`;
    },

    setQuoteRequestNumber(article, event) {
      article.quote_request_number = event?.value || null;
    },

    async fetchQuoteRequestsNumbers(event) {
      let search = event?.target.value || null;
      try {
        const response = await axios.get(route('quoteRequests.numbers.get'), {
          params: {
            quoteRequestNumber: search,
          }
        });

        if (response.data && response.data.quoteRequestNumbers) {
          this.formatQuoteRequestsNumbers(response.data.quoteRequestNumbers);
        }
      } catch (errors) {
        this.$emit('error', errors);
      }

      return false;
    },

    formatQuoteRequestsNumbers(numbers) {
      this.quoteRequestNumbers = numbers.map(number => ({
        label: number.request_number,
        value: number.request_number
      }));
    },

    saveQuoteRequest() {
      this.form.post(route('quoteRequests.store'), {
        onSuccess: (resp) => {
          this.$emit('added-success', 'Zapytanie ofertowe zostało utworzone.');
          this.hide();
        },
        onError: (errors) => {
          console.log(errors);
          let errorMessages = Object.values(errors).flat().join(', ');
          this.$emit('added-error', 'Błąd podczas tworzenia zapytania: ' + errorMessages);
        }
      });
    },


    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}
</script>


<style scoped>
.widthFirstTd {
  width: 150px;
}
</style>